import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { FormHubspotComponent } from 'src/app/shared/components/form-hubspot/form-hubspot.component';
@Component({
  selector: 'app-posgrados-general-uno',
  templateUrl: './posgrados-general-uno.component.html',
  styleUrls: ['./posgrados-general-uno.component.scss']
})
export class PosgradosGeneralUnoComponent implements OnInit {
  idNuestroInstituto: string;
  idAgenda: string;
  idCarreras: string;
  datos: any;
  agenda: any;
  items: any[] = [];
  region: any;
  htmlAranceles: string;
  htmlAdmision: string;
  htmlInscripcion: string;
  htmlEstudiar: string;
  seccion: string;
  id: string;
  language: any;
  titleLink: any;
  currentRoute: string;
  carrer: string;
  venue: string;
  carrertype: string;
  regionURL: string;
  formLink: string;
  dataSlider: any[] = [];
  htmlAutoridades: string;
  color = "#013974";
  sedeDeInteres = '';
  formEspecial = ['1325', '1326', '1327', '1326', '466', '469', '472', '475', '478', '481'];
  button_authority_state: boolean
  constructor(public dialog: MatDialog, private services: GeneralService, private aRouter: ActivatedRoute, public route: ActivatedRoute, private router: Router) {
    this.aRouter.params.forEach((params: Params) => {
      this.language = params['language'];
      this.titleLink = params['title'];
      this.seccion = this.aRouter.snapshot.params['seccion']
      this.id = this.aRouter.snapshot.params['id']
    });



    this.services.getCarreraByTitlePosgrados(this.id, this.seccion).subscribe(data => {
      if (data.data) {
        this.datos = data.data[0];
        if (this.datos.form_hubspot) {
          this.sedeDeInteres = this.route.snapshot.queryParamMap.get('sede_de_interes');
          this.agregarParametro(data.data[0])
        }

        this.color = this.datos.color;
        this.dataSlider.push({
          id: 1,
          pathMobile: this.datos.pathMobile,
          path: this.datos.path
        });
        this.button_authority_state = data.data[0].button_authority_state
      } else {
        router.navigate(['404']);
      }

    })


  }



  ngOnInit(): void {
  }



  agregarParametro(data) {
    console.log(data)

    let regionFormateada = '';
    let region = '';
    let areaFormateada = '';
    let area = '';
    if (data.area) {
      region = (data.region[0].title)
      regionFormateada = this.quitarTildes(data.region[0].title.toLowerCase().replace(/\s+/g, '_'));
    }
    if (data.area) {
      area = data.area.name;
      areaFormateada = this.quitarTildes(data.area.name.toLowerCase().replace(/\s+/g, '_'));
    }

    const queryParams: { [key: string]: any } = {};
    queryParams[`area_de_interes`] = area;
    queryParams[`carrera_de_posgrado__${areaFormateada}_`] = data.title;
    queryParams[`sede_de_interes_posgrado`] = region;
    queryParams[`carreras_de_grado__${regionFormateada}_`] = this.quitarTildes(data.title);


    console.log(queryParams)
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }
  quitarTildes(texto: string) {
    const tildes = {
      á: 'a',
      é: 'e',
      í: 'i',
      ó: 'o',
      ú: 'u',
      ü: 'u',
      Á: 'A',
      É: 'E',
      Í: 'I',
      Ó: 'O',
      Ú: 'U',
      Ü: 'U',
    };

    return texto.replace(/[áéíóúüÁÉÍÓÚÜ]/g, letra => tildes[letra]);
  }
}
