import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ShellModule} from '../modules/shell/shell.module';
import {ShellRoutingModule} from '../modules/shell/shell-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TokenInterceptor} from './interceptors/token.interceptor';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import { BusquedaModule } from '../modules/busqueda/busqueda.module';
import { BusquedaRoutingModule } from '../modules/busqueda/busqueda-routing.module';
import { EventosRoutingModule } from '../modules/eventos/eventos-routing.module';
import { EventosModule } from '../modules/eventos/eventos.module';
import { PosgradosModule } from '../modules/posgrados/posgrados.module';
import { SharedModule } from '../shared/components/shared.module';
import { GeneralModule } from '../modules/general/general.module';
import { GeneralRoutingModule } from '../modules/general/general-routing.module';
import { ArancelesModule } from '../modules/aranceles/aranceles.module';
//
@NgModule({
  providers:[
    {
      provide:HTTP_INTERCEPTORS,
      useClass:TokenInterceptor,
      multi:true
    }
  ],
  imports:[
    CommonModule, 
    BrowserAnimationsModule, 
    ShellRoutingModule,
    BusquedaRoutingModule,
    SharedModule,
    EventosRoutingModule,
    GeneralRoutingModule,
    ArancelesModule

   ],
   
  exports:[
    ShellModule,
    BusquedaModule,
    EventosModule,
    PosgradosModule,
    GeneralModule,
    ArancelesModule

  ]
})
export class CoreModule {
}
