import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { ModalGeneralComponent } from 'src/app/shared/components/modal-general/modal-general.component';
import { FormContactoComponent } from '../form-contacto/form-contacto.component';
import { FormHubspotComponent } from 'src/app/shared/components/form-hubspot/form-hubspot.component';

@Component({
  selector: 'app-info-column-posgrado',
  templateUrl: './info-column-posgrado.component.html',
  styleUrls: ['./info-column-posgrado.component.scss']
})
export class InfoColumnPosgradoComponent implements OnInit {
  @Input() datos;
  @Input() color;
  @Input() button_authority_state;
  venue = ""
  carrer = ""
  autoridades: any;
  
  constructor(public dialog: MatDialog, private aRouter: ActivatedRoute, private services: GeneralService) {
    this.aRouter.params.forEach((params: Params) => {
      this.venue = this.aRouter.snapshot.params['seccion']
      this.carrer = this.aRouter.snapshot.params['id']
    });
  }

  ngOnInit(): void {
    this.services.getAutoridades(this.carrer).subscribe(
      data => {
        if (data.data) {
          let html_content = "";
          for (let item of data.data[0].authority) {
            if (!item.email) {
              item.email = ""
            }
            html_content += '' +
              '<div class="row " >' +
              '<div class="mt-2 pt-3 pl-3 pr-3 ml-3">' +
              '   <img onerror="this.src="./assets/img/default.png" src="' + item.path + '">' +
              '</div>' +
              '<div class="col-md-8 pt-3 pl-3 pr-3" style="color: #1e3e6f">' +
              '<p><b>' + item.name + '</b></p>' +
              '<p><span>' + item.place + '</span></p>' +
              '<p>' + item.venue + '</p>' +
              '<div>' +
              '<p><b>' + item.email + '</b></p>' +
              '       </div>' +
              '     </div>' +
              '  </div>' +
              ' ';

          }
          //   this.list.push({title:data.data[0].display, body:html_content})
          this.autoridades = {
            body: html_content,
            title: data.data[0].display
          }
      
        }
      },
      error => {
      }
    )
  }

  openModal(item: any) {
    this.dialog.open(ModalGeneralComponent, { panelClass: 'custom-dialog-container', data: { html: item.body, title: item.title } });
  }
  openModalConsulta() {

    if (this.datos.form_hubspot) {
      this.dialog.open(FormHubspotComponent);
    } else {
      let dialogRef = this.dialog.open(FormContactoComponent, { panelClass: 'custom-dialog-container', data: { curso: null } });
      let instance = dialogRef.componentInstance;
      instance.venue = this.venue
      instance.carrer = this.carrer
      const sub = dialogRef.componentInstance.closeModal.subscribe(() => {
        this.dialog.closeAll();
      });
    }
  }

}


