import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  id: string = '';
  data: any;

  constructor(private aRouter: ActivatedRoute) {
    this.aRouter.params.subscribe((params: Params) => {
      this.id = params['id'];
      this.handleId(this.id);
    });
  }

  handleId(id: string) {
    console.log(id)
    switch (id) {
      case 'musica':
        this.data = { id: "banner-area-musica", url: "/es/educacion-continua/artes-y-ciencias-musicales", img: "musica.jpg" }
        break;
      case 'ciencias-economicas-y-negocios':
        this.data = { id: "banner-area-economicas-y-negocios", url: "/es/educacion-continua/ciencias-economicas-y-negocios", img: "economicas.jpg" }
        break;
      case 'ciencias-medicas':
        this.data = { id: "banner-area-ciencias-medicas", url: "/es/educacion-continua/ciencias-medicas", img: "medicas.jpg" }
        break;

      case 'ciencias-sociales':
        this.data = { id: "banner-area-ciencias-sociales", url: "/es/educacion-continua/ciencias-sociales", img: "sociales.jpg" }
        break;

      case 'derecho':
        this.data = { id: "banner-area-derecho", url: "/es/educacion-continua/derecho", img: "derecho.jpg" }
        break;

      case 'filosofia-y-letras':
        this.data = { id: "banner-area-filosofia-y-letras", url: "/es/educacion-continua/filosofia-y-letras", img: "filo.jpg" }
        break;

      case 'ingenieria-y-ciencias-agrarias':
        this.data = { id: "banner-area-ingenieria", url: "/es/educacion-continua/ingenieria-y-ciencias-agrarias", img: "ingenieria.jpg" }
        break;

      case 'psicologia-y-psicopedagogia':
        this.data = { id: "banner-area-psicologia-y-psicopedagogia", url: "/es/educacion-continua/psicologia-y-psicopedagogia", img: "psico.jpg" }
        break;

      case 'teologia':
        this.data = { id: "banner-area-teologia", url: "/es/educacion-continua/teologia", img: "teologia.jpg" }
        break;

      default:
        break;
    }
  }

  ngOnInit(): void {
  }




}
