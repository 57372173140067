<div class="card container"  [ngStyle]="{'background': color}">
    <div class="row mt-3">
        <div class="col-3 text-center">
            <img class="icon" src="./assets/img/calendario.png" alt="">
        </div>
        <div class="col-9">
            <span class="label blanco ">INICIO</span><br>
            <span class=" blanco  " *ngIf="datos.date_search_start">{{datos.date_search_start }}</span>
            <br>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-3">
        </div>
        <div class="col-9">
            <span class="label blanco">FINALIZACIÓN</span><br>
            <span class=" blanco " *ngIf="datos.date_ending">{{datos.date_ending }}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="vr-white"></div>
        </div>
    </div>

    <!---------------------------------------------------------->
    <div class="row mt-3">
        <div class="col-3 text-center">
            <img class="icon-min" src="./assets/img/ordenador-portatil.png" alt="">
        </div>
        <div class="col-9">
            <span class="label blanco ">MODALIDAD</span><br>
            <span class=" blanco ">{{datos.modality}}</span>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="vr-white"></div>
        </div>
    </div>
    <!---------------------------------------------------------->

    <div class="row mt-3">
        <div class="col-3 text-center">
            <img class="icon" src="./assets/img/boton-editar.png" alt="">
        </div>
        <div class="col-9">
            <span class="label blanco ">CURSADA</span><br>
            <span class=" blanco ">{{datos.coursed}}</span>
            <br>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="vr-white"></div>
        </div>
    </div>

    <!---------------------------------------------------------->

    <div class="row mt-3">
        <div class="col-3 text-center">
            <img class="icon" src="./assets/img/reloj.png" alt="">
        </div>
        <div class="col-9">
            <span class="label blanco ">DURACIÓN</span><br>
            <span class=" blanco ">{{datos.workload}} </span>
            <br>
        </div>
    </div>
</div>


<div class="card-blue container mt-3" (click)="openModalConsulta()">
    <div class="row">
        <div class="col-12" >
            <span class="label blanco pl-3">INFORMES E INSCRIPCIÓN</span><br>
            <span class=" blanco  pl-3">{{datos.mail_contact}}</span>
        </div>
    </div>
</div>
<div *ngFor="let item of datos.buttonStudyPlan;index as i">
    <div *ngIf="i==0 && item.state" (click)="openModal(item)" class="card-gray-dark container pointer mt-3">
        <div class="row">
            <div class="col-3 ">
                <img class="icon-arancel pl-3" src="./assets/img/teclado.png" alt="">
            </div>
            <div class="col-9 col-arancel ">
                <span class=" blanco ">{{item.title}}</span>
                <br>
            </div>
        </div>
    </div>

    <div *ngIf="i>0 && i!=4 && item.state" (click)="openModal(item)" class="card-gray container  pointer mt-3">
        <div class="row">
            <div class="col-12  ">
                <span class=" blanco pl-3">{{item.title}}</span>
            </div>
        </div>
    </div>
</div>
<div   *ngIf="button_authority_state" class="card-gray container  pointer mt-3" (click)="openModal(autoridades)">
    <div class="row">
        <div class="col-12  ">
            <span class=" blanco pl-3">Autoridades</span>
        </div>
    </div>
</div>

<div *ngIf="datos.buttonStudyPlan && datos.buttonStudyPlan[4] && datos.buttonStudyPlan[4].state " class="card-buttom container mt-5  mb-3 pointer"
    (click)="openModal(datos.buttonStudyPlan[4])">
    <div class="row">
        <div class="col">
            <span class=" azul ">Terminos y condiciones</span>
        </div>
    </div>
</div>


<div class="container mt-5  mb-3">
    <div class="row">
        <div class="col-12 text-center">
            <span class=" subtitle azul ">Contactate con nosotros</span>
        </div>
        <div class="col-12 text-center">
            <a  [href]="datos.whatsapp" Target="_blank" >
                <img class="icon-wsp pointer" src="./assets/img/whatsapp.png" alt="">
            </a>
        </div>
    </div>
    <div class="row">
        <div class="col-5 text-center col-seguinos">
            <span class=" subtitle azul ">Seguinos en redes</span>
        </div>
        <div class="col text-center">
            <a [href]="datos.instagram" Target="_blank" >
                <img class="icon-redes" src="./assets/img/instagram-logo-circle.png" alt="">
            </a>
        </div>
        <div class="col text-center">
            <a [href]="datos.facebook" Target="_blank" >
                <img class="icon-redes" src="./assets/img/facebook.png" alt="">
            </a>
        </div>
    </div>
</div>